<template>
    <div class="userCenter">
        <div class="user-header">
            <div class="header-con">
                <div class="logo">
                    <img src="../../assets/Avatar.png" alt="">
                </div>
                <ul class="user-info">
                    <li>
                        <p class="module">
                            <!--                            <span class="tit">姓名/职位</span>-->
                            <span class="val" v-if="userInfo">{{userInfo.name}}</span>
                            <span class="val" v-if="userInfo.userinfo&&userInfo.userinfo.job">/ {{userInfo.userinfo.job}}</span>
                            <span class="val" v-if="!userInfo.name&&!userInfo.userinfo">-- / --</span>
                        </p>
                    </li>
                    <!--                    <li>-->
                    <!--                        <p class="module">-->
                    <!--                            <span class="tit">证件号码</span>-->
                    <!--                            <span class="val" v-if="userInfo.userinfo&&userInfo.userinfo.id_number">{{userInfo.userinfo.id_number}}</span>-->
                    <!--                            <span class="val" v-else>&#45;&#45;</span>-->
                    <!--                        </p>-->
                    <!--                        <p class="module">-->
                    <!--                            <span class="tit">联系方式</span>-->
                    <!--                            <span class="val"-->
                    <!--                                  v-if="userInfo.userinfo&&userInfo.userinfo.phone">{{userInfo.userinfo.phone}}</span>-->
                    <!--                            <span class="val" v-else>&#45;&#45;</span>-->
                    <!--                        </p>-->
                    <!--                    </li>-->
                </ul>
            </div>
        </div>
        <div class="nft-container">
            <ul class="nft-title">
                <li v-for="(item,index) in nav" :key="index" :class="{'active':activeNav==item.val}"
                    @click="tabNav(item)">
                    <span>{{item.title}}</span>
                </li>
            </ul>
            <div v-if="!activeNav">
                <ul class="nft-list">
                    <li class="nft-item" v-for="(item,index) in nftList" :key="index">
                        <p class="image">
                            <el-image
                                    :src="nftDefault"
                                    class="image"
                                    fit="cover"
                            ></el-image>
                        </p>
                        <p class="con">
                            <span class="tit">AvatarDAO LP Membership</span>
<!--                            <span class="val">#{{join(item,4)}}</span>-->
                        </p>
                    </li>
                </ul>
                <div class="no-data" v-if="nftList.length==0">
                    <img src="../../assets/noData.png" alt="">
                    <p class="txt">No Data</p>
                </div>
            </div>
            <div class="apply-list" v-else>
                <div v-loading="isLoading">
                    <ul class="apply-tit">
                        <li class="item">
                            Project name
                        </li>
                        <li class="item">
                            Proposal time
                        </li>
                        <li class="item">
                            Proposal Type
                        </li>
                        <!--                        <li class="item">-->
                        <!--                            Status-->
                        <!--                        </li>-->
                        <li class="item">
                            operate
                        </li>
                    </ul>
                    <ul class="applys" v-for="(item,index) in list" :key="index">
                        <li class="item">
                            {{item.title}}
                        </li>
                        <li class="item">
                            {{formatTime(item.created_at)}}
                        </li>
                        <li class="item">
                            <span v-if="item.category==1">DAO governance</span>
                            <span v-if="item.category==0">project investment</span>
                        </li>
                        <!--                        <li class="item">-->
                        <!--                            <span v-if="item.status==1">approved</span>-->
                        <!--                            <span v-if="item.status==0">under review</span>-->
                        <!--                            <span v-if="item.status==-1">unauthorized</span>-->
                        <!--                        </li>-->
                        <li class="item">
                            <el-button type="info" class="btn" @click="toDetails(item)">
                                view
                            </el-button>
                        </li>
                    </ul>
                    <div class="pageCount" v-if="total>pageView">
                        <el-pagination
                                background
                                layout="prev, pager, next"
                                :page-size="pageView"
                                :total="total"
                                @current-change="changeVal">
                        </el-pagination>
                    </div>
                    <div class="no-data" v-if="total==0">
                        <img src="../../assets/noData.png" alt="">
                        <p class="txt">No Data</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapState, mapMutations} from "vuex";
    import nftDefault from "../../assets/nftDefault.gif";
    import {Init} from "../../plugins/web3";
    import avatar from "../../ABI/avatar.json";

    export default {
        name: "index",
        data() {
            return {
                nav: [{
                    title: "NFT",
                    val: 0
                }, {
                    title: "My Proposal",
                    val: 1
                }],
                activeNav: 0,
                nftDefault,
                serialNumber: 0,
                nftList: [],
                pageView: 0,
                pageCount: 1,
                total: 0,
                list: [],
                isLoading: false
            }
        },
        computed: {
            ...mapState('user', ['isJoin', 'isMe']),
            status() {
                if (this.userInfo.userinfo) {
                    return this.userInfo.userinfo.status;
                }
                return 0;
            }
        },
        mounted() {
            this.info();
            this.getInfo();
            this.getApplys();
            if (this.status == 1 && this.isJoin != 0 && !this.isMe) {
                this.setData({isMe: true});
            }
        },
        methods: {
            ...mapMutations('user', ['setData']),
            ...mapActions('user', ['info']),
            join(num, length) {
                return (Array(length).join('0') + num).slice(-length);
            },
            tabNav(item) {
                this.activeNav = item.val;
                if (this.activeNav == 1) {
                    this.pageCount = 1;
                    this.getApplys();
                }
            },
            toDetails(item) {
                this.$router.push({name: "voteDetails", query: {id: item.id}});
            },
            async changeVal(val) {
                this.pageCount = val;
                this.getApplys();
            },
            async getApplys() {
                this.isLoading = true;
                setTimeout(() => {
                    this.isLoading = false;
                }, 5000);
                let res = await this.api.auth(this.token).proposalOwner({
                    page: this.pageCount
                });
                if (res.data.code == 200) {
                    this.isLoading = false;
                    this.list = res.data.data.data;
                    this.total = res.data.data.total;
                    this.pageView = res.data.data.per_page;
                }
            },
            async getInfo() {
                let provider = Init();
                let web3 = new this.Web3(provider);
                let myContract = await new web3.eth.Contract(
                    avatar.ABI,
                    this.configCon.contract_address
                );
                this.serialNumber = await myContract.methods.balanceOf(this.username).call({
                    from: this.username
                });
                for (let i = 0; i < this.serialNumber; i++) {
                    let res = await myContract.methods.tokenOfOwnerByIndex(this.username, i).call();
                    this.nftList.push(res);
                }
            }
        }
    }
</script>
<style lang="scss">
    .applys {
        .btn {
            min-width: 60px;
            height: 36px;
            text-transform: uppercase;
        }
    }

    .userCenter {
        .el-loading-spinner .path {
            stroke: #3A56FF;
        }
    }
</style>
<style lang="scss" scoped>
    .userCenter {
        width: 100%;
        min-height: 100vh;
        padding-bottom: 100px;
    }

    .user-header {
        width: 100%;
        height: 178px;
        background-color: #2C2C34;
        display: flex;
        align-items: center;

        .header-con {
            margin: 0 auto;
            width: 1200px;
            display: flex;
            align-items: center;

            .logo {
                margin-right: 29px;
                width: 56px;
                height: 56px;
                border-radius: 50%;
                background: #CBCBDD;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 46px;
                    height: 46px;
                }
            }

            .user-info {
                li {
                    display: flex;
                    flex-wrap: wrap;
                    font-size: 14px;
                    font-weight: 500;

                    .module {
                        margin-right: 26px;
                        display: flex;

                        .tit {
                            color: #9AA5B6;
                        }

                        .val {
                            margin-left: 5px;
                            color: #FFFFFF;
                        }
                    }
                }

                li:nth-of-type(1) {
                    margin-bottom: 12px;
                }
            }
        }
    }

    .nft-container {
        margin: 0 auto;
        width: 1200px;

        .nft-title {
            width: 100%;
            height: 103px;
            line-height: 103px;
            color: #0F0F0F;
            font-size: 22px;
            font-weight: 500;
            display: flex;
            align-items: center;

            li {
                margin-right: 70px;
                font-size: 18px;
                color: #0F0F0F;
                font-weight: 500;
                line-height: 28px;
                cursor: pointer;
            }

            .active {
                color: #3A56FF;

                span {
                    border-bottom: 2px solid #3A56FF;
                }
            }
        }

        .nft-list {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(4, calc(25% - 10px));
            /*grid-template-rows: auto auto auto;*/
            //height: 780px;   //padding 有个20的宽度
            grid-gap: 30px 27px;

            .nft-item {
                width: 278px;
                min-height: 326px;
                border-radius: 14px;
                box-shadow: 0px 0px 12px 0px #E3E5F1;
                background-color: #FFFFFF;

                .image {
                    width: 100%;
                    height: 266px;
                    background-color: #000000;
                    border-radius: 14px 14px 0 0;
                }

                .con {
                    width: 100%;
                    height: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    box-sizing: border-box;
                    padding: 0 16px;

                    .tit {
                        font-size: 18px;
                        color: #0D0D08;
                    }

                    .val {
                        font-size: 12px;
                        color: #7D7E80;
                    }
                }
            }
        }

        .apply-list {
            width: 1200px;
            min-height: 577px;
            border: 1px solid #F5F5F5;

            .apply-tit {
                width: 100%;
                height: 44px;
                line-height: 44px;
                box-sizing: border-box;
                padding: 0 36px;
                display: grid;
                grid-template-columns: repeat(4, 25%);
                background-color: #F5F5F5;
                color: #707A8A;

                li:nth-last-child(1) {
                    text-align: right;
                }

                li:nth-last-child(2) {
                    text-align: center;
                }
            }

            .applys {
                @extend .apply-tit;
                height: 54px;
                line-height: 54px;
                background-color: #FFFFFF;
                font-size: 14px;
                color: #292B33;
                font-weight: 500;
                border-bottom: 1px solid #F5F5F5;
            }
        }
    }

    @media screen and (max-width: 1200px) {
        .user-header .header-con, .nft-container {
            width: 100%;
            box-sizing: border-box;
            padding: 0 20px;
        }
        .user-header .header-con, .nft-container .nft-list {
            grid-template-columns: repeat(3, calc(33% - 10px));
            justify-content: left;
        }
        .nft-container .apply-list {
            width: 90%;

            .apply-tit {
                grid-template-columns: repeat(4, 25%);
            }
        }
    }

    @media screen and (max-width: 900px) {
        .user-header .header-con, .nft-container .nft-list {
            grid-template-columns: repeat(2, calc(45% - 10px));
            justify-content: center;
        }
    }

    @media screen and (max-width: 750px) {
        .userCenter {
            padding-bottom: 20px;
        }
        .user-header .header-con, .nft-container .nft-list {
            grid-template-columns: repeat(1, calc(100% - 10px));
            justify-content: center;

            .nft-item {
                width: 100%;
            }
        }
        .nft-container .apply-list {
            width: 100%;

            .apply-tit {
                padding: 0 5px;
                grid-template-columns: repeat(3, 30% 40% 30%);

                li:nth-of-type(2) {
                    display: none;
                }
            }
        }
    }

    .pageCount {
        width: 100%;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
</style>
