<template>
    <div class="Apply">
        <div class="group-box">
            <div class="public-header">
                <div class="title">
                    apply
                </div>
            </div>
            <el-form :label-position="labelPosition" ref="validateForm" label-width="80px" :model="formLabelAlign">
                <el-form-item
                        prop="file"
                        :rules="[ { required: true, message: 'Please upload file'} ]"
                        label="Project logo">
                    <div class="upload-file-logo">
                        <div class="file-txt">
                            <p class="upload-txt" v-if="!formLabelAlign.logo">
                                <i class="el-icon-plus"></i>
                            </p>
                            <p class="upload-txt filename" v-else>{{formLabelAlign.logo.name}}</p>
                        </div>
                        <input type="file"
                               accept="image/png,image/gif,image/jpg,image/jpeg"
                               @change="changeFile($event,'logo')"/>
                    </div>
                </el-form-item>
                <el-form-item
                        prop="projectName"
                        :rules="[ { required: true, message: 'Please enter a project name'} ]"
                        label="Project Name"
                >
                    <el-input v-model="formLabelAlign.projectName" placeholder="Project Name"></el-input>
                </el-form-item>
                <el-form-item
                        prop="projectLink"
                        :rules="[ {
                        required: true,
                        // message: 'Please enter project website',
                         trigger: 'blur',
                            validator: validateLink
                        } ]"
                        label="Project Website">
                    <el-input v-model="formLabelAlign.projectLink" placeholder="Project Website"></el-input>
                </el-form-item>
                <el-form-item
                        prop="name"
                        :rules="[ { required: true, message: 'Please enter Contact Name'} ]"
                        label="Contact Name">
                    <el-input v-model="formLabelAlign.name" placeholder="Contact Name"></el-input>
                </el-form-item>
                <el-form-item
                        prop="email"
                        :rules="[ {
                            required: true,
                            // message: 'Please enter email address',
                            trigger: 'blur',
                            validator: validateAddress
                        }
                        ]"
                        label="E-mail">
                    <el-input v-model="formLabelAlign.email" placeholder="E-mail"></el-input>
                </el-form-item>
                <el-form-item
                        prop="title"
                        :rules="[ { required: true, message: 'Please enter contact title'} ]"
                        label="Title">
                    <el-input v-model="formLabelAlign.title" placeholder="Title"></el-input>
                </el-form-item>
                <el-form-item
                        prop="description"
                        :rules="[ { required: true, message: 'Please enter project focus'} ]"
                        label="Project Description">
                    <el-input type="textarea" v-model="formLabelAlign.description" placeholder="highlights？"></el-input>
                </el-form-item>
                <el-form-item
                        prop="info"
                        :rules="[ { required: true, message: 'Please enter Whitepaper or brief'} ]"
                        label="Whitepaper or brief">
                    <el-input type="textarea" v-model="formLabelAlign.info"
                              placeholder="To help members make decisions, please provide links to the financing plan, white paper or any other information that you think is relevant (if there are multiple links, please wrap them or separate them with Spaces)"></el-input>
                </el-form-item>
                <el-form-item
                        prop="amount"
                        :rules="[ {
                            required: true,
                            // message: 'Please enter Requested Funds'
                             trigger: 'blur',
                            validator: validateAmount
                        } ]"
                        label="Requested Funds">
                    <el-input v-model="formLabelAlign.amount" placeholder="$ 30000"></el-input>
                </el-form-item>
                <el-form-item
                        prop="wallet"
                        :rules="[ { required: true, message: 'Please enter Wallet address'} ]"
                        label="Wallet address">
                    <el-input v-model="formLabelAlign.wallet" placeholder="Wallet address"></el-input>
                </el-form-item>
                <el-form-item label="Investment Status: Do you already have investors for your project?">
                    <el-radio-group v-model="investorIs">
                        <el-radio :label="1">YES</el-radio>
                        <el-radio :label="2">NO</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item
                        prop="file"
                        :rules="[ { required: true, message: 'Please upload file'} ]"
                        label="Additional files you would like to share with us">
                    <div class="upload-file">
                        <div class="file-txt">
                            <i class="iconfont icon-wenjianjia"></i>
                            <p class="upload-txt" v-if="!formLabelAlign.file">click or drag files to this area to
                                upload</p>
                            <p class="upload-txt filename" v-else>{{formLabelAlign.file.name}}</p>
                        </div>
                        <input type="file"
                               @change="changeFile($event,'file')"/>
                    </div>
                </el-form-item>
                <el-form-item label="Proposal Privacy">
                    <el-radio-group v-model="formLabelAlign.publicIs">
                        <el-radio :label="1">public</el-radio>
                        <el-radio :label="2">private</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div class="next-btn" v-if="!userInfo.proposal_state&&configCom&&configCom.proposal_state"
                 @click="submitForm('validateForm')">
                <span>SUBMIT</span>
            </div>
        </div>
        <el-dialog
                title="View project"
                :visible.sync="dialogVisible"
                width="500px"
                class="elDialog-prop"
                :before-close="handleClose">
            <div class="content">
                Copy link of the project
                <span class="blue" style="color: #3A56FF;cursor: pointer;">{{shareUrl}}</span>
                Please note that this project does not appear on the ‘Projects’ page yet and will only show up if it has
                received more than 3 votes.
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" class="btn" @click="toDetails">Copy link to see</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapActions, mapState} from "vuex";

    let AWS = require("aws-sdk");
    export default {
        name: "Apply",
        data() {
            return {
                labelPosition: "top",
                dialogVisible: false,
                s3: new AWS.S3({ // AWS 认证相关
                    accessKeyId: 'AKIAZUCS5FS34O2CZ2PT',
                    secretAccessKey: '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',
                    region: 'ap-east-1'
                }),
                formLabelAlign: {
                    logo: "",
                    logoUrl: "",
                    projectName: "",
                    projectLink: "",
                    name: "",
                    email: "",
                    title: "",
                    description: "",
                    info: "",
                    amount: "",
                    wallet: "",
                    file: "",
                    fileUrl: "",
                    publicIs: 1
                },
                investorIs: 1,
                projectId: null,
                configCom: null
            }
        },
        computed: {
            ...mapState('user', ['userInfo']),
            shareUrl() {
                if (this.projectId) {
                    return window.location.origin + '/voteDetails?id=' + this.projectId
                }
                return 0;
            }
        },
        mounted() {
            if (this.username) {
                this.info();
            }
            if (this.userInfo) {
                this.commonConfig();
            }
        },
        methods: {
            ...mapActions('user', ['info']),
            validateLink(rule, value, callback) {
                let reg = /(http|https):\/\/([\w.]+\/?)\S*/;
                if (value === '') {
                    callback(new Error('Please enter project website'));
                } else if (!reg.test(value)) {
                    callback(new Error('Please enter the correct link address'));
                } else {
                    callback();
                }
            },
            validateAddress(rule, value, callback) {
                let reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                if (value === '') {
                    callback(new Error('Please enter email address'));
                } else if (!reg.test(value)) {
                    callback(new Error('Please input the correct email address'));
                } else {
                    callback();
                }
            },
            validateAmount(rule, value, callback) {
                if (value === '') {
                    callback(new Error('Please enter Requested Funds'));
                } else if (typeof Number(value) != 'number') {
                    callback(new Error('Please key in numbers'));
                } else if (Number(value) > Number(this.configCom.max_amt)) {
                    callback(new Error('The requested funds cannot be greater than ' + this.configCom.max_amt));
                } else {
                    callback();
                }
            },
            async commonConfig() {
                let res = await this.api.auth(this.token).commonConfig();
                if (res.data.code == 200) {
                    this.configCom = res.data.data;
                }
            },
            toDetails() {
                this.copyUrl(this.shareUrl, 'Copy successfully');
                // this.$router.push({name: 'voteDetails', query: {id: this.projectId}});
            }
            ,
            handleClose() {
                this.dialogVisible = false;
            }
            ,
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.sendForm();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }
            ,
            changeFile(e, name) {
                const file = e.target.files[0];
                this.formLabelAlign[name] = file;
                this.upLoadFile(file, name);
            }
            ,
            upLoadFile(file, name) {
                /**let key = new Date().getTime() + "_" + Math.random().toFixed(2) + "." + file.name.split(".")[1];
                const params = {
                    Bucket: 'avatar-dao', // 存储桶名称
                    Key: key, // 文件名，重名会覆盖
                    ContentType: file.type, // 文件类型
                    Body: file, // 具体的文件
                    'Access-Control-Allow-Credentials': '*',
                    'ACL': 'public-read'
                };
                let loading = this.$loading({
                    lock: true,
                    text: "upload file...",
                    background: "rgba(0, 0, 0, 0.7)",
                });**/
                this.formLabelAlign[name + 'Url'] = 'https://app.avatardao.vc/default.png';
                return;
                /** this.s3.upload(params, {
                    // queueSize: 1 // 并发数
                }).send((err, data) => {
                    if (err) {
                        loading.close();
                        this.$message({
                            message: "upload error",
                            type: "error",
                        })
                    } else {
                        loading.close();
                        this.$message({
                            message: "Uploaded file successfully",
                            type: "success",
                        })
                        this.formLabelAlign[name + 'Url'] = data.Location;
                    }
                }); **/
            }
            ,
            async sendForm() {
                let loading = this.$loading({
                    lock: true,
                    text: "submitting...",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                let res = await this.api.auth(this.token).proposalStore({
                    logo: this.formLabelAlign.logoUrl,
                    category: 0,
                    type: this.formLabelAlign.publicIs == 1 ? 0 : 1,
                    amt: this.formLabelAlign.amount,
                    address: this.formLabelAlign.wallet,
                    title: this.formLabelAlign.projectName,
                    link: this.formLabelAlign.projectLink,
                    name: this.formLabelAlign.name,
                    email: this.formLabelAlign.email,
                    job: this.formLabelAlign.title,
                    describe: this.formLabelAlign.description,
                    has_investor: this.investorIs == 1 ? 1 : 0,
                    other: this.formLabelAlign.info,
                    file: this.formLabelAlign.fileUrl
                });
                if (res.data.code == 200) {
                    loading.close();
                    this.projectId = res.data.data.no;
                    this.dialogVisible = true;
                    this.$refs['validateForm'].resetFields();
                } else {
                    loading.close();
                }
            }
        }
    }
</script>
<style lang="scss">
    .Apply {

        .el-input__inner {
            width: 100%;
            height: 60px;
            background-color: #F8F9FD;
            border-radius: 2px;
            border: 1px solid #D6D9E5;
            color: #0F0F0F;
            font-size: 16px;
        }

        .el-textarea__inner {
            @extend .el-input__inner;
            height: 190px;
        }

        .el-input__inner::-webkit-input-placeholder, .el-textarea__inner::-webkit-input-placeholder {
            color: #87899D;
        }

        .el-form-item__label {
            color: #0F0F0F;
            font-size: 16px;
            font-weight: 500;
        }

        .investor-status {
            display: flex;

            .btn {
                cursor: pointer;
                margin-right: 28px;
                width: 168px;
                height: 60px;
                text-align: center;
                line-height: 60px;
                border-radius: 4px;
                border: 1px solid #3A56FF;
                background-color: #FFFFFF;
                color: #3A56FF;
                font-size: 20px;
            }

            .select-btn {
                background-color: #3A56FF;
                color: #FFFFFF;
            }
        }

        .upload-file {
            position: relative;
            width: 100%;
            height: 190px;
            background: #F8F9FD;
            border-radius: 2px;
            border: 1px solid #D6D9E5;

            .file-txt {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: #87899D;
            }

            .filename {
                color: #3A56FF;
            }

            input {
                position: absolute;
                left: 0;
                top: 0;
                z-index: 999;
                width: 100%;
                height: 100%;
                display: inline-block;
                opacity: 0;
                cursor: pointer;
            }


            .iconfont {
                font-size: 40px;
            }

            .upload-txt {
                font-size: 18px;
            }
        }

        .upload-file-logo {
            @extend .upload-file;
            width: 360px;

            .el-icon-plus {
                font-size: 30px;
                color: #87899D;
                font-weight: bold;
            }
        }
    }

    .dialog-footer {
        .btn {
            width: 144px;
            height: 48px;
            background: #3A56FF;
            border-radius: 2px;
            font-size: 14px;
        }
    }
</style>
<style lang="scss" scoped>
    .Apply {
        width: 100%;
        min-height: 100vh;
        padding-bottom: 80px;

        .public-header {
            padding: 0px 0 60px 0;

            .title {
                text-transform: uppercase;
                font-size: 36px;
                font-weight: bold;
            }
        }

        .group-box {
            margin: 0 auto;
            width: 820px;
        }

        .avatar-tit {
            margin: 50px 0;
            color: #575757;
            font-size: 14px;
            line-height: 28px;

            p {
                margin-bottom: 20px;
            }
        }

        .next-btn {
            margin-top: 100px;
            width: 169px;
            height: 60px;
            background-color: #3A56FF;
            border-radius: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #FFFFFF;
            font-weight: 500;
            cursor: pointer;

            span {
                margin-right: 5px;
                font-size: 18px;
            }
        }

        .upload-item {
            cursor: pointer;
            width: 100%;
            height: 60px;
            line-height: 60px;
            border-radius: 2px;
            border: 1px solid #D6D9E5;
            background: #F8F9FD;
            font-size: 18px;
            color: #87899D;
            box-sizing: border-box;
            padding: 0 20px;
        }
    }

    @media screen and (max-width: 850px) {
        .Apply .group-box {
            width: 95%;
        }
    }
</style>
<style lang="scss">
    @media screen and (max-width: 850px) {
        .Apply .el-dialog {
            width: 95%;
        }
    }
</style>
