<template>
    <div class="voteDetails">
        <div class="title" v-if="info">
            {{info.title}}
        </div>
        <div class="title" v-else>
            --
        </div>
        <div class="back-title">
            <button class="btn" @click="back">
                <i class="iconfont icon-jiantou_xiangyou"></i>
                <span>View all</span>
            </button>
        </div>
        <div class="project-box" v-if="info&&info.content">
            <div class="project-info">
                <p class="tit">Brief intro</p>
                <p class="discription" v-if="info">
                    {{info.content}}
                </p>
                <p class="discription" v-else>--</p>
                <div class="images" v-if="info.logo">
                    <el-image
                            src="/default.png"
                            class="img"
                            fit="cover"
                    ></el-image>
                </div>
            </div>
            <div class="project-right">
                <div class="status" v-if="info">
                    <i class="iconfont icon-line_jishiqi"></i>
                    <span v-if="info.status==0">under review</span>
                    <span v-if="info.status==-1">rejected</span>
                    <span v-if="info.status==1&&!info.state">voting</span>
                    <span v-if="info.state&&info.status==1">approved</span>
                    <span v-if="!info.state&&isEnd">Ended</span>
                </div>
                <div class="org-square-root-bar" v-if="info">
                    <div class="org-yes-vote"
                         :style="'width:calc('+yesVotes+'% - 0px);'"></div>
                    <div class="org-no-vote"
                         :style="'width:calc('+noVotes+'% - 0px);'">
                    </div>
                </div>
                <div class="vote-rate" v-if="info">
                    <p class="org-yes-vote">
                        <i class="iconfont icon-duihao"></i>
                        <span v-if="yesVotes">{{yesVotes | filterNum(2)}}%</span>
                        <span v-else>0%</span>
                    </p>
                    <p class="org-no-vote">
                        <i class="iconfont icon-duihao"></i>
                        <span v-if="noVotes">{{noVotes | filterNum(2)}}%</span>
                        <span v-else>0%</span>
                    </p>
                </div>
                <div class="btn-status" v-if="!isEnd&&isJoin!=0 && info.status==1&&!info.state">
                    <div class="btn" v-if="info&&info.votes>0">
                        <el-button type="primary" class="operate" @click="clickVote(1)">YES</el-button>
                    </div>
                    <div class="btn" v-if="info&&info.votes==0">
                        <el-button type="primary" disabled class="operate">voted</el-button>
                    </div>
                    <div class="btn" v-if="info&&info.votes>0">
                        <el-button type="info" class="operate" @click="clickVote(2)">NO</el-button>
                    </div>
                </div>
                <div class="btn-status" v-if="isEnd">
                    <div class="btn">
                        <el-button type="primary" disabled class="operate">Over</el-button>
                    </div>
                </div>
                <div class="btn-status" v-if="isJoin==0">
                    <div class="btn">
                        <el-button type="primary" disabled class="operate">Vote yes</el-button>
                    </div>
                </div>
                <div class="project-data" v-if="info.link">
                    <p class="tit">Project data</p>
                    <ul>
                        <li>Project Website</li>
                        <li>
                            <a href="" v-if="info">{{info.link}}</a>
                            <a href="" v-else>--</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="underReview" v-else>
            <img src="../assets/audit.png" class="image">
            <p class="status-txt">
                <span>Under Review</span>
            </p>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import {Init} from "../plugins/web3";
    import avatar from "../ABI/avatar.json";

    export default {
        name: "voteDetails",
        data() {
            return {
                info: null,
                details: null,
                isEnd: false
            }
        },
        computed: {
            ...mapState('user', ['auditInfo', 'isJoin']),
            yesVotes() {
                if (this.info && this.info.totalVotes == 0) {
                    return this.info.yesVotes / this.auditInfo.totalSupply * 100;
                }
                if (this.info && this.info.totalVotes != 0) {
                    return this.info.yesVotes / this.info.totalVotes * 100;
                }
                return 0;
            },
            noVotes() {
                if (this.info && this.info.totalVotes == 0) {
                    return this.info.noVotes / this.auditInfo.totalSupply * 100;
                }
                if (this.info && this.info.totalVotes != 0) {
                    return this.info.noVotes / this.info.totalVotes * 100;
                }
                return 0;
            }
        },
        async activated() {
            await this.getVoteDetails();
            await this.detailsInfo();
        },
        methods: {
            async clickVote(vote) { //投票
                let provider = Init();
                let web3 = new this.Web3(provider);
                let myContract = await new web3.eth.Contract(
                    avatar.ABI,
                    this.configCon.contract_address
                );
                let loading = this.$loading({
                    lock: true,
                    text: "Voting...",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                let res = await myContract.methods.submitVote(this.$route.query.id, vote).send({from: this.username}).on('error', (err) => {
                    loading.close();
                    this.$message({
                        message: err.message,
                        type: "error"
                    })
                });
                if (res.blockHash) {
                    loading.close();
                    await this.getVoteDetails();
                    await this.detailsInfo();
                    this.$message({
                        message: 'successful vote ',
                        type: "success"
                    })
                }
            },
            async getVoteDetails() {
                let res = await this.api.auth(this.token).proposalIndex({
                    id: this.$route.query.id
                });
                if (res.data.code == 200) {
                    this.info = res.data.data;
                }
            },
            back() {
                this.$router.push({name: "Vote"});
            },
            async detailsInfo() {
                let provider = Init();
                let web3 = new this.Web3(provider);
                let myContract = await new web3.eth.Contract(
                    avatar.ABI,
                    this.configCon.contract_address
                );
                let res = await myContract.methods.getProposalInfo(this.$route.query.id).call({
                    from: this.username
                });
                this.info = {...this.info, ...res};
                console.log("info",this.info);
                let nowTime = new Date().getTime();
                if (this.info.deadline != 0 && nowTime > this.info.deadline * 1000 && !this.info.state) { //已结束 未完成
                    this.isEnd = true;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .voteDetails {
        width: 100%;
        min-height: 100vh;
    }

    .title {
        margin-top: 100px;
        width: 100%;
        text-align: center;
        font-size: 36px;
        font-weight: 600;
        color: #0F0F0F;
        line-height: 50px;
        text-transform: uppercase;
    }

    .back-title {
        margin: 0 auto;
        width: 1200px;
        height: 102px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .btn {
            width: 133px;
            height: 52px;
            background: #EEF1FF;
            border-radius: 1px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #3A56FF;
            border: none;

            .iconfont {
                margin-right: 5px;
                font-size: 20px;
                transform: rotate(180deg);
            }

            span {
                font-size: 16px;
                font-weight: 600;
            }
        }
    }

    .project-box {
        margin: 0 auto;
        width: 1200px;
        min-height: 710px;
        box-sizing: border-box;
        padding: 42px 58px;
        border: 1px solid #F1F3FC;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .project-info {
            width: 50%;

            .tit {
                margin-bottom: 24px;
                font-size: 26px;
                font-weight: 500;
                color: #0F0F0F;
            }

            .discription {
                margin-bottom: 37px;
                font-size: 14px;
                color: #575757;
                line-height: 28px;
            }

            .images {
                width: 100%;
                height: 299px;

                .img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .project-right {
            width: 442px;

            .status {
                margin-bottom: 20px;
                color: #41BF92;
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: uppercase;

                .iconfont {
                    margin-right: 10px;
                }
            }

            .org-square-root-bar {
                position: relative;
                width: 100%;
                height: 10px;
                background-color: #F2F3FA;
                border-radius: 5px;

                .org-yes-vote {
                    position: absolute;
                    left: 0;
                    height: 10px;
                    transition: .4s linear;
                    background-color: #41BF92;
                    border-radius: 5px 0 0 5px;
                }

                .org-no-vote {
                    position: absolute;
                    right: 0;
                    height: 10px;
                    background-color: #D87EEC;
                    border-radius: 0 5px 5px 0;
                }
            }

            .org-square-root-bar:after {
                content: "";
                position: absolute;
                border-right: 2px solid #FFFFFF;
                height: 10px;
                right: 50%;
                top: 0;
            }

            .btn {
                margin-bottom: 24px;

                .operate {
                    width: 100%;
                    height: 54px;
                    font-size: 14px;
                }
            }

            .vote-rate {
                margin-bottom: 20px;
                width: 100%;
                height: 35px;
                display: flex;

                p {
                    width: 50%;
                    height: 35px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 12px;

                    .iconfont {
                        font-weight: 500;
                    }

                    span {
                        margin-left: 5px;
                    }
                }

                .org-yes-vote {
                    color: #41BF92;
                }

                .org-no-vote {
                    color: #D87EEC;
                }
            }

            .project-data {
                margin-top: 48px;
                width: 100%;
                min-height: 247px;
                box-sizing: border-box;
                border: 1px solid #E7EAF5;
                padding: 20px;

                .tit {
                    margin-bottom: 30px;
                    font-size: 16px;
                    font-weight: 500;
                    color: #0F0F0F;
                }

                ul {
                    margin-bottom: 20px;
                    font-size: 12px;

                    > li {
                        color: #575757;
                    }

                    li {
                        a {
                            color: #3A56FF;
                            font-weight: 500;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1200px) {
        .back-title {
            width: 100%;
            height: 80px;
            box-sizing: border-box;
            padding: 0 20px;
        }
        .project-box {
            width: 100%;
            justify-content: center;

            .project-info {
                width: 80%;
            }

            .project-right {
                margin-top: 20px;
                width: 80%;
            }
        }
    }

    @media screen and (max-width: 750px) {
        .back-title {
            width: 100%;
            height: 80px;
            box-sizing: border-box;
            padding: 0 20px;
        }
        .project-box {
            width: 100%;
            justify-content: center;
            padding: 10px 15px;

            .project-info {
                width: 95%;
            }

            .project-right {
                width: 95%;
            }
        }
    }

    .underReview {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .image {
            margin-bottom: 50px;
            width: 176px;
        }

        .status-txt {
            text-align: center;
            font-size: 18px;
            color: #0F0F0F;
            font-weight: 500;
        }
    }
</style>
