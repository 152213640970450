<template>
    <div class="Apply">
        <div class="group-box">
            <div class="public-header">
                <div class="title">
                    Submit a DAO governance proposal
                </div>
            </div>
            <el-form :label-position="labelPosition" ref="validateForm" label-width="80px" :model="formLabelAlign">
                <el-form-item
                        prop="projectName"
                        :rules="[ { required: true, message: 'Please enter a title'} ]"
                        label="Title"
                >
                    <el-input v-model="formLabelAlign.projectName" placeholder="Title"></el-input>
                </el-form-item>
                <el-form-item
                        prop="description"
                        :rules="[ { required: true, message: 'Please enter content'} ]"
                        label="Content">
                    <el-input type="textarea" v-model="formLabelAlign.description" placeholder="Content"></el-input>
                </el-form-item>
            </el-form>
            <div class="next-btn"
                 v-if="!userInfo.proposal_state&&configCom&&configCom.proposal_state"
                 @click="submitForm('validateForm')">
                <span>submit</span>
            </div>
        </div>
        <el-dialog
                title="View project"
                :visible.sync="dialogVisible"
                width="500px"
                class="elDialog-prop"
                :before-close="handleClose">
            <div class="content">
                Copy link of the project
                <span class="blue" style="color: #3A56FF;cursor: pointer;">{{shareUrl}}</span>
                Please note that this project does not appear on the ‘Projects’ page yet and will only show up if it has
                received more than 3 votes.
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" class="btn" @click="toDetails">Copy link to see</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapActions, mapState} from "vuex";

    export default {
        name: "Apply",
        data() {
            return {
                labelPosition: "top",
                dialogVisible: false,
                formLabelAlign: {
                    projectName: "",
                    description: "",
                },
                projectId: "",
                configCom: null
            }
        },
        computed: {
            ...mapState('user', ['userInfo']),
            shareUrl() {
                if (this.projectId) {
                    return window.location.origin + '/voteDetails?id=' + this.projectId
                }
                return 0;
            }
        },
        mounted() {
            if (this.username) {
                this.info();
            }
            if (this.userInfo) {
                this.commonConfig();
            }
        },
        methods: {
            ...mapActions('user', ['info']),
            async commonConfig() {
                let res = await this.api.auth(this.token).commonConfig();
                if (res.data.code == 200) {
                    this.configCom = res.data.data;
                }
            },
            toDetails() {
                this.copyUrl(this.shareUrl, 'Copy successfully');
                // this.$router.push({name: 'voteDetails', query: {id: this.projectId}});
            },
            handleClose() {
                this.dialogVisible = false;
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.sendForm();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            async sendForm() {
                let loading = this.$loading({
                    lock: true,
                    text: "submitting...",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                let res = await this.api.auth(this.token).proposalStore({
                    category: 1,
                    address: this.username,
                    title: this.formLabelAlign.projectName,
                    describe: this.formLabelAlign.description
                });
                if (res.data.code == 200) {
                    loading.close();
                    this.projectId = res.data.data.no;
                    this.dialogVisible = true;
                    this.$refs['validateForm'].resetFields();
                } else {
                    loading.close();
                }
            }
        }
    }
</script>
<style lang="scss">
    .Apply {

        .el-input__inner {
            width: 100%;
            height: 60px;
            background-color: #F8F9FD;
            border-radius: 2px;
            border: 1px solid #D6D9E5;
            color: #0F0F0F;
            font-size: 18px;
        }

        .el-textarea__inner {
            @extend .el-input__inner;
            height: 190px;
        }

        .el-input__inner::-webkit-input-placeholder, .el-textarea__inner::-webkit-input-placeholder {
            color: #87899D;
        }

        .el-form-item__label {
            color: #0F0F0F;
            font-size: 18px;
            font-weight: 500;
        }

        .investor-status {
            display: flex;

            .btn {
                cursor: pointer;
                margin-right: 28px;
                width: 168px;
                height: 60px;
                text-align: center;
                line-height: 60px;
                border-radius: 4px;
                border: 1px solid #3A56FF;
                background-color: #FFFFFF;
                color: #3A56FF;
                font-size: 20px;
            }

            .select-btn {
                background-color: #3A56FF;
                color: #FFFFFF;
            }
        }

        .upload-file {
            position: relative;
            width: 100%;
            height: 190px;
            background: #F8F9FD;
            border-radius: 2px;
            border: 1px solid #D6D9E5;

            .file-txt {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: #87899D;
            }

            .filename {
                color: #3A56FF;
            }

            input {
                position: absolute;
                left: 0;
                top: 0;
                z-index: 999;
                width: 100%;
                height: 100%;
                display: inline-block;
                opacity: 0;
                cursor: pointer;
            }


            .iconfont {
                font-size: 40px;
            }

            .upload-txt {
                font-size: 18px;
            }
        }

        .upload-file-logo {
            @extend .upload-file;
            width: 360px;

            .el-icon-plus {
                font-size: 30px;
                color: #87899D;
                font-weight: bold;
            }
        }
    }

    .dialog-footer {
        .btn {
            width: 144px;
            height: 48px;
            background: #3A56FF;
            border-radius: 2px;
            font-size: 14px;
        }
    }
</style>
<style lang="scss" scoped>
    .Apply {
        width: 100%;
        min-height: 100vh;

        .group-box {
            margin: 0 auto;
            width: 820px;
        }

        .avatar-tit {
            margin: 50px 0;
            color: #575757;
            font-size: 14px;
            line-height: 28px;

            p {
                margin-bottom: 20px;
            }
        }

        .next-btn {
            margin-top: 100px;
            width: 169px;
            height: 60px;
            background-color: #3A56FF;
            border-radius: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #FFFFFF;
            font-weight: 500;
            cursor: pointer;

            span {
                margin-right: 5px;
                font-size: 18px;
            }
        }

        .upload-item {
            cursor: pointer;
            width: 100%;
            height: 60px;
            line-height: 60px;
            border-radius: 2px;
            border: 1px solid #D6D9E5;
            background: #F8F9FD;
            font-size: 18px;
            color: #87899D;
            box-sizing: border-box;
            padding: 0 20px;
        }
    }

    @media screen and (max-width: 850px) {
        .Apply .group-box {
            width: 95%;
        }
    }
</style>
<style lang="scss">
    @media screen and (max-width: 850px) {
        .Apply .el-dialog {
            width: 95%;
        }
    }
</style>
