<template>
    <div class="audit">
        <div class="audit-status">
            <img src="../assets/audit.png" v-if="status==0" class="image">
            <img src="../assets/audit-success.png" v-if="status==1" class="image">
            <img src="../assets/audit-no.png" v-if="status==-1" class="image">
            <p class="status-txt">
                <span v-if="status==0">under review...</span>
                <span v-if="status==1&&isJoin==0">Review succeeded</span>
                <span v-if="status==1&&isJoin!=0">Join successful</span>
                <span v-if="status==-1">Audit failure</span>
            </p>
            <el-button class="btn" v-if="status==-1" @click="backApply">Re-apply</el-button>
            <el-button class="btn" v-if="status==0" disabled>Invest now</el-button>
            <el-button class="btn" v-if="status==1&&isJoin==0" type="primary" v-loading="isLoading"
                       @click="handelInvest">
                {{investText}}
            </el-button>
        </div>
    </div>
</template>

<script>
    import {mapState, mapActions, mapMutations} from "vuex";
    import {Init} from "../plugins/web3";
    import avatar from "../ABI/avatar.json";
    import usdtABI from "../ABI/usdt.json";

    export default {
        name: "audit",
        data() {
            return {
                isLoading: false,
                investText: "Invest now"
            }
        },
        async mounted() {
            if (this.username) {
                let res = await this.info();
                if (!res.data.data.userinfo) {
                    this.$router.push({name: "Apply"});
                }
            }
            if (this.status == 1 && this.isJoin == 0 && !this.isContribute) {
                this.setData({isContribute: true});
            }
        },
        computed: {
            ...mapState('user', ['auditInfo', 'username', 'isJoin', 'isContribute']),
            status() {
                if (this.userInfo.userinfo) {
                    return this.userInfo.userinfo.status;
                }
                return 0;
            }
        },
        methods: {
            ...mapActions('user', ['info']),
            ...mapMutations('user', ['setData']),
            backApply() {
                this.$router.push({name: "Apply"});
            },
            async handelInvest() {
                //初始化合约
                let provider = Init();
                let web3 = new this.Web3(provider);
                let myContract = await new web3.eth.Contract(
                    avatar.ABI,
                    this.configCon.contract_address
                );
                let isJoin = await myContract.methods.balanceOf(this.username).call();
                let isWhite = await myContract.methods.membersWhiteList(this.username).call();
                this.setData({isJoin});
                if (isJoin == 0 && isWhite) {
                    this.isApproved(web3);
                }
                if (!isWhite) {
                    this.$message({
                        message: 'The current wallet address is not in the whitelist',
                        type: "error"
                    })
                }
            },
            compute(price, num) {
                let start = price.indexOf('0');
                let startPrice = price.substring(0, start);
                let endPrice = price.substring(start, price.length);
                let computeNum = startPrice * num;
                return computeNum + endPrice;
            },
            async isApproved(web3) {
                let myContract = await new web3.eth.Contract(usdtABI.ABI, this.configCon.token_address);
                let balance = await myContract.methods.balanceOf(this.username).call();
                let nftNum = (this.auditInfo.votesTotal - this.auditInfo.totalSupply) > this.userInfo.userinfo.nft_count ? this.userInfo.userinfo.nft_count : (this.auditInfo.votesTotal - this.auditInfo.totalSupply);
                if (Number(balance) >= Number(this.auditInfo.votePrice * nftNum)) {
                    this.isLoading = true;
                    this.investText = "under authorization...";
                    try {
                        let num = this.compute(this.auditInfo.votePrice, nftNum);
                        //(this.auditInfo.votePrice * nftNum).toString()
                        let application = await myContract.methods.approve(this.configCon.contract_address, num).send(
                            {
                                from: this.username
                            }
                        ).on('error', (err) => {
                            this.$message({
                                message: err.message,
                                type: "error"
                            })
                            this.isLoading = false;
                            this.investText = "Invest now";
                        });
                        // console.log("application", application);
                        if (application.blockHash) {
                            this.isLoading = true;
                            this.investText = "Joining...";
                            let joinContract = await new web3.eth.Contract(avatar.ABI, this.configCon.contract_address);
                            let res = await joinContract.methods.memberJoin(nftNum).send({from: this.username}).on('error', (err) => {
                                this.$message({
                                    message: err.message,
                                    type: "error"
                                })
                                this.isLoading = false;
                                this.investText = "Invest now";
                            });
                            if (res.blockHash) {
                                this.isLoading = false;
                                this.investText = "Join successfully";
                                this.handelInvest();
                            }
                        }
                    } catch (e) {
                        console.log("e", e);
                    }

                } else {
                    this.$message({
                        message: 'Insufficient account balance, please recharge and try again',
                        type: "info"
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .audit {
        width: 100%;
        min-height: 100vh;
    }

    .audit-status {
        margin: 0 auto;
        width: 1200px;
        height: 713px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #FFFFFF;

        .image {
            width: 227px;
            height: 202px;
        }

        .status-txt {
            width: 100%;
            text-align: center;
            line-height: 180px;
            font-size: 18px;
            color: #0F0F0F;
            font-weight: 500;
        }

        .btn {
            margin: 0;
            width: 442px;
            height: 54px;
            font-size: 16px;
        }
    }

    @media screen and (max-width: 1200px) {
        .audit-status {
            width: 100%;
        }
        .audit-status .btn {
            width: 90%;
        }
    }
</style>
