<template>
    <div class="vote">
        <div class="vote-title">
            Vote
        </div>
        <div class="vote-wrap" v-if="startList.length>0">
            <p class="title">Voting</p>
            <ul class="list">
                <li class="item" v-for="(item,index) in startList" :key="index">
                    <div class="item-title">
                        {{item.title}}
                    </div>
                    <div class="type">
                        <span v-if="!item.type">(Public)</span>
                        <span v-else>(Private)</span>
                    </div>
                    <div class="status">
                        <i class="iconfont icon-line_jishiqi"></i>
                        <!--<span v-if="item==1">结束：2天6小时38分钟</span>-->
                        <span v-if="item.status==0">under review</span>
                        <span v-if="item.status==-1">rejected</span>
                        <span v-if="item.status==1&&!item.state">voting</span>
                        <span v-if="item.state">approved</span>
                    </div>
                    <div class="org-square-root-bar">
                        <div class="org-yes-vote"
                             :style="'width:calc('+item.yesVotesRate+'% - 0px);'"
                        ></div>
                        <div class="org-no-vote"
                             :style="'width:calc('+item.noVotesRate+'% - 0px);'"
                        ></div>
                    </div>
                    <div class="vote-rate">
                        <p class="org-yes-vote">
                            <i class="iconfont icon-duihao" v-if="item.yesVotesRate>item.noVotesRate"></i>
                            <span>{{item.yesVotesRate | filterNum(2)}}%</span>
                        </p>
                        <p class="org-no-vote">
                            <i class="iconfont icon-duihao" v-if="item.yesVotesRate<item.noVotesRate"></i>
                            <span>{{item.noVotesRate | filterNum(2)}}%</span>
                        </p>
                    </div>
                    <div class="btn">
                        <el-button v-if="isJoin!=0&&item.votes>0&&!item.state" type="primary" @click="toDetails(item)">
                            Voting
                        </el-button>
                        <el-button v-if="isJoin==0&&!item.state" type="primary" disabled>
                            Voting
                        </el-button>
                        <el-button disabled v-if="item.state">Completed</el-button>
                    </div>
                </li>
            </ul>
        </div>
        <div class="vote-wrap mar5top" v-if="endList.length>0">
            <p class="title">Rejected</p>
            <ul class="list">
                <li class="item" v-for="(item,index) in endList" :key="index">
                    <!--                    <div class="logo">-->
                    <!--                        <img src="" alt="" class="image">-->
                    <!--                    </div>-->
                    <div class="item-title">
                        {{item.title}}
                    </div>
                    <div class="type">
                        <span v-if="item.type">(Public)</span>
                        <span v-else>(Private)</span>
                    </div>
                    <div class="status">
                        <i class="iconfont icon-line_jishiqi"></i>
                        <span>Ended</span>
                    </div>
                    <div class="org-square-root-bar">
                        <div class="org-yes-vote"
                             :style="'width:calc('+item.yesVotesRate+'% - 0px);'"
                        ></div>
                        <div class="org-no-vote"
                             :style="'width:calc('+item.noVotesRate+'% - 0px);'"
                        ></div>
                    </div>
                    <div class="vote-rate">
                        <p class="org-yes-vote">
                            <i class="iconfont icon-duihao" v-if="item.yesVotesRate>item.noVotesRate"></i>
                            <span>{{item.yesVotesRate | filterNum(2)}}%</span>
                        </p>
                        <p class="org-no-vote">
                            <i class="iconfont icon-duihao" v-if="item.yesVotesRate<item.noVotesRate"></i>
                            <span>{{item.noVotesRate | filterNum(2)}}%</span>
                        </p>
                    </div>
                    <div class="btn">
                        <el-button disabled>Ended</el-button>
                    </div>
                </li>
            </ul>
        </div>
        <div class="no-data" v-if="startList.length==0&&endList.length==0">
            <img src="../assets/noData.png" alt="">
            <p class="txt">No Data</p>
        </div>
    </div>
</template>

<script>
    import {Init} from "../plugins/web3";
    import avatar from "../ABI/avatar.json";
    import {mapState} from "vuex";

    export default {
        name: "vote",
        data() {
            return {
                list: [],
                endList: [],
                startList: []
            }
        },
        activated() {
            this.getVoteList();
        },
        computed: {
            ...mapState('user', ['auditInfo', 'isJoin', 'token']),
        },
        methods: {
            toDetails(item) {
                this.$router.push({name: "voteDetails", query: {id: item.id}});
            },
            async getVoteList() {
                let res = await this.api.auth(this.token).proposalIndex({id: null, category: 0});
                if (res.data.code == 200) {
                    let arr = res.data.data;
                    this.callCon(arr);
                }
            },
            async callCon(arr) {
                //初始化合约
                let provider = Init();
                let web3 = new this.Web3(provider);
                let myContract = await new web3.eth.Contract(
                    avatar.ABI,
                    this.configCon.contract_address
                );
                let nowTime = new Date().getTime();
                await arr.map(async (item) => {
                    let res = await myContract.methods.getProposalInfo(item.id).call({
                        from: this.username
                    });
                    let yesVotesRate;
                    let noVotesRate;
                    if (res.totalVotes != 0) {
                        yesVotesRate = res.yesVotes / res.totalVotes * 100;
                        noVotesRate = res.noVotes / res.totalVotes * 100;
                    } else {
                        console.log("res.yesVotes",res.yesVotes);
                        console.log("this.auditInfo.totalSupply",this.auditInfo.totalSupply);
                        yesVotesRate = res.yesVotes / this.auditInfo.totalSupply * 100;
                        noVotesRate = res.noVotes / this.auditInfo.totalSupply * 100;
                    }
                    let obj = {yesVotesRate, noVotesRate, id: item.id, title: item.title, type: item.type, ...res};
                    if (res.deadline > 0) {
                        if (!res.state && nowTime > res.deadline * 1000) { //结束
                            // this.endList.push(obj);
                            this.setArr('endList', obj);
                        } else {
                            // this.startList.push(obj);
                            this.setArr('startList', obj);
                        }
                    }
                });
                console.log("start", this.startList);
            },
            setArr(name, obj) {
                if (this[name].findIndex((fruit) => fruit.id === obj.id) == -1) {
                    this[name].unshift(obj);
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    .vote {
        width: 100%;
        min-height: 100vh;
    }

    .vote-title {
        padding-top: 100px;
        width: 100%;
        text-align: center;
        font-size: 36px;
        font-weight: 600;
        color: #0F0F0F;
        line-height: 50px
    }

    .vote-wrap {
        margin: 0 auto;
        width: 1200px;

        .title {
            width: 100%;
            height: 110px;
            line-height: 110px;
            font-size: 20px;
            font-weight: 500;
            color: #575757;
        }

        .list {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(3, calc(33% - 10px));
            /*grid-template-rows: auto auto auto;*/
            //height: 780px;   //padding 有个20的宽度
            grid-gap: 30px 27px;

            .item {
                height: 268px;
                background: #FFFFFF;
                box-shadow: 0px 0px 12px 0px rgba(172, 201, 239, 0.05);
                border-radius: 2px;
                border: 1px solid #F1F3FC;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .item-title {
                    font-size: 18px;
                    color: #0F0F0F;
                    font-weight: 500;
                }

                .logo {
                    width: 42px;
                    height: 42px;

                    .image {
                        width: 100%;
                        height: 100%;
                        background-color: #8A8EAA;
                        border-radius: 50%;
                    }
                }

                .type {
                    line-height: 48px;
                    font-size: 14px;
                    color: #0F0F0F;
                    font-weight: 500;
                }

                .status {
                    margin-bottom: 20px;
                    color: #41BF92;
                    font-size: 12px;
                }

                .org-square-root-bar {
                    position: relative;
                    width: 334px;
                    height: 10px;
                    background-color: #F2F3FA;
                    border-radius: 5px;

                    .org-yes-vote {
                        position: absolute;
                        left: 0;
                        height: 10px;
                        transition: .4s linear;
                        background-color: #41BF92;
                        border-radius: 5px 0 0 5px;
                    }

                    .org-no-vote {
                        position: absolute;
                        right: 0;
                        height: 10px;
                        background-color: #D87EEC;
                        border-radius: 0 5px 5px 0;
                    }
                }

                .vote-rate {
                    width: 334px;
                    height: 35px;
                    display: flex;

                    p {
                        width: 50%;
                        height: 35px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 12px;

                        .iconfont {
                            font-weight: 500;
                        }

                        span {
                            margin-left: 5px;
                        }
                    }

                    .org-yes-vote {
                        color: #41BF92;
                    }

                    .org-no-vote {
                        color: #D87EEC;
                    }
                }

                .org-square-root-bar:after {
                    content: "";
                    position: absolute;
                    border-right: 2px solid #FFFFFF;
                    height: 10px;
                    right: 50%;
                    top: 0;
                }

                .btn {
                    margin: 20px 0;
                    font-size: 14px;

                    .el-button {
                        font-weight: bold;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    .mar5top {
        margin-top: 30px;
    }

    @media screen and (max-width: 1200px) {
        .vote-wrap {
            width: 100%;
            box-sizing: border-box;
            padding: 0 10px;

            .list {
                grid-template-columns: repeat(2, calc(45% - 10px));
                justify-content: center;
            }
        }
    }

    @media screen and (max-width: 800px) {
        .vote-wrap {
            width: 100%;
            box-sizing: border-box;
            padding: 0 10px;

            .list {
                grid-template-columns: repeat(1, calc(90% - 10px));
                justify-content: center;
            }
        }
    }

    @media screen and (max-width: 500px) {
        .vote-wrap {
            width: 100%;
            box-sizing: border-box;
            padding: 0 10px;

            .list {
                grid-template-columns: repeat(1, calc(98% - 10px));
                justify-content: center;
            }
        }
    }
</style>
