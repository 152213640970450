import { render, staticRenderFns } from "./newProposal.vue?vue&type=template&id=238d47ce&scoped=true"
import script from "./newProposal.vue?vue&type=script&lang=js"
export * from "./newProposal.vue?vue&type=script&lang=js"
import style0 from "./newProposal.vue?vue&type=style&index=0&id=238d47ce&prod&lang=scss"
import style1 from "./newProposal.vue?vue&type=style&index=1&id=238d47ce&prod&lang=scss&scoped=true"
import style2 from "./newProposal.vue?vue&type=style&index=2&id=238d47ce&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "238d47ce",
  null
  
)

export default component.exports